<template>
  <div class="h-screen bg-white">
    <video
      ref="camera"
      :width="300"
      :height="300"
      autoplay
      class="hidden"
    />
    <div v-if="exam">
      <AssessmentInstructions
        v-if="!assessmentStarted"
        :exam="exam"
        @begin-test="startExam($event)"
      />
      <AssessmentContainer
        v-else
        :organisation-id="organisationId"
        :exam="exam"
        :anti-cheat-data="antiCheatData"
      />
    </div>
    <div
      v-else
      class="flex-grow my-12"
    >
      <Loader />
    </div>
  </div>
</template>

<script>
import AssessmentContainer from '@components/Assessments/AssessmentContainer'
import AssessmentInstructions from '@components/Assessments/AssessmentInstructions'
import Loader from '@components/Loader'

import { mapGetters } from 'vuex'
import { antiCheating } from '@utils/mixins/antiCheating'

export default {
  components: {
    AssessmentContainer,
    AssessmentInstructions,
    Loader
  },

  mixins: [antiCheating],

  data() {
    return {
      assessmentStarted: false
    }
  },

  computed: {
    ...mapGetters({
      haveExamsLoaded: 'exams/haveExamsLoaded',
      examBySlug: 'exams/examBySlug',
      isTokenValid: 'candidates/isTokenValid'
    }),

    /**
     * @return {String}
     */
    examSlug() {
      return this.$route.params.assessment
    },

    /**
     * @return {string}
     */
    candidateId() {
      return this.$route.query.candidate
    },

    /**
     * @return {string}
     */
    jobId() {
      return this.$route.query.job
    },

    /**
     * @return {string}
     */
    sittingToken() {
      return this.$route.query.sitting
    },

    /**
     * @return {string}
     */
    token() {
      return this.$route.query.token
    },

    /**
     * To be passed to the next part of the journey
     *
     * @return {Object}
     */
    query() {
      return {
        candidate: this.candidateId,
        job: this.jobId,
        sitting: this.sittingToken,
        token: this.token
      }
    },

    /**
     * @return {Object}
     */
    exam() {
      return this.examBySlug(this.examSlug)
    },

    /**
     * @return {String}
     */
    organisationId() {
      return this.exam.organisationId
    }
  },

  beforeMount() {
    window.addEventListener('beforeunload', this.launchReloadWarning)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.launchReloadWarning)
    })
  },

  created() {
    if (!this.haveExamsLoaded) {
      this.$store.dispatch('exams/getExams', this.token)
    }

    this.validateToken()
  },

  methods: {
    /**
     * @param {Boolean} toggle
     */
    startExam(toggle) {
      this.assessmentStarted = toggle
      if (window.innerWidth > 1024) {
        this.requestFullScreenAssessment()
      }

      if (toggle) {
        this.$store.dispatch('candidates/recordExamStarted', this.exam.slug)
      }
    },

    /**
     * Launch reload page generic browser alert
     */
    launchReloadWarning(event) {
      if (!this.assessmentStarted) {
        return
      }
      event.preventDefault()
      // Chrome requires returnValue to be set.
      event.returnValue = ''
    },

    /**
     * Trigger a token validation
     */
    validateToken() {
      if (this.isTokenValid) {
        // Already valid
        return
      }

      if (!this.candidateId || !this.token) {
        console.error(
          'Something is missing',
          this.candidateId,
          this.token
        )
        // @TODO 2019-11-27 This error isn’t being caught by the handler to
        // show the user an error message
        throw new Error('Cannot find required query')
      }
      this.$store.dispatch('candidates/validateToken', {
        candidateId: this.candidateId,
        jobId: this.jobId,
        sittingToken: this.sittingToken,
        token: this.token
      })
    }
  }
}
</script>
