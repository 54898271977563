<template>
  <div class="select-none">
    <div v-if="haveExamsLoaded || isExample">
      <header
        :class="['w-full relative flex items-center p-6 shadow z-10 h-16 lg:h-auto bg-white', isExample ? 'justify-between' : 'justify-start']"
      >
        <AssessmentExampleEndButton
          v-if="isExample"
          :modal-open="endModalOpen"
          :is-showing-answers="showAnswers"
          @open="endModalOpen = true"
          @close="endModalOpen = false"
          @showAnswers="setShowAnswers"
        />

        <div :class="['mx-auto', {'overflow-x-auto max-w-screen-xl pb-4 px-6 xl:px-0' : questionCount > 20}]">
          <ul
            v-if="haveQuestionsLoaded(exam.slug) || isExample"
            class="hidden md:flex"
          >
            <li
              v-for="n in questionCount"
              :key="n"
              class="px-2"
            >
              <a
                href="javascript:;"
                class="px-2 text-gray-600"
                :class="questionNumberStyles(n)"
                @click="handleNavigation(n)"
              >
                {{ n }}
              </a>
            </li>
          </ul>
        </div>

        <Timer
          v-if="(exam.isTimed && haveQuestionsLoaded(exam.slug)) || (exam.isTimed && isExample)"
          :class="{'md:self-start ml-4': !isExample}"
          :time-in-minutes="exam.minutes"
          :stop="submitInProgress"
          @time-is-up="timeIsUp"
        />
      </header>

      <AssessmentDrives
        v-if="typeDrives"
        :is-example="isExample"
        :submit-in-progress="submitInProgress"
        @endTest="submitResponsesAndRedirect()"
      />

      <AssessmentPersonality
        v-if="typePersonality"
        :is-example="isExample"
        :page-number="questionIndex"
        :next-text="nextText"
        :question-preface="exam.questionPreface"
        :submit-error="submitError"
        :submit-in-progress="submitInProgress"
        :personality-traits="exam.personalityTraits"
        :is-last-question="isLastQuestion"
        :exam-slug="exam.slug"
        @endTest="submitResponsesAndRedirect()"
        @prev="prev()"
        @next="next()"
      />

      <div
        v-for="(question, index) in filteredQuestions(exam.slug)"
        :key="index"
      >
        <div
          v-if="index === questionIndex"
          class="bg-white"
        >
          <section
            v-if="typeChoice || typeLikely"
            class="lg:flex"
          >
            <div class="sticky w-full p-6 bg-gray-100 lg:w-2/3 lg:h-screen lg:py-12 lg:px-16">
              <template v-if="typeChoice && !questionHasAsset(question)">
                <h1 class="text-4xl font-medium">
                  Question {{ index + 1 }}
                </h1>
                <p
                  class="mt-6 text-lg prose"
                  v-html="question.bodyHtml"
                >
                </p>
              </template>

              <img
                v-if="question.image_url"
                :class="questionImageClasses(question, exam)"
                :src="question.image_url"
                alt="Question Image"
              />

              <div
                v-if="question.articleHtml"
                class="mb-6 markdown"
                v-html="question.articleHtml"
              />

              <div
                v-if="question.graphs && question.graphs.length"
                class="p-6 bg-white"
              >
                <AssessmentGraph
                  v-for="(graph, index) in question.graphs"
                  :key="index"
                  :graph-data="graph"
                />
              </div>

              <div
                v-if="question.table"
                :class="['markdown overflow-x-scroll md:overflow-x-auto bg-white p-6', markdownTableRowStyling(question.table)]"
                v-html="marked(question.table)"
              />

              <div v-if="typeLikely">
                <div
                  v-if="!question.articleHtml"
                  class="px-4 py-2 mb-6 text-sm bg-white border-l-4 border-red-600"
                >
                  <span>
                    Read the passage and select the most effective and least effective actions to take in response to the situation.
                  </span>
                </div>

                <div
                  class="text-lg text-gray-800"
                  v-html="question.bodyHtml"
                />
              </div>
            </div>
            <div
              class="w-full p-6 overflow-y-scroll lg:w-1/3 lg:h-screen lg:py-12 lg:px-16 lg:shadow-left"
            >
              <div class="h-1 mb-6 bg-gray-200">
                <div
                  class="h-1 bg-secondary"
                  :style="{ 'width': progress + '%'}"
                />
              </div>

              <h1
                v-if="(typeChoice && questionHasAsset(question) ) || typeLikely"
                class="text-4xl font-medium"
              >
                Question {{ index + 1 }}
              </h1>

              <!-- COMPONENTIZE -->
              <template v-if="typeChoice">
                <p
                  v-if="questionHasAsset(question)"
                  class="mt-6 text-lg"
                  v-html="question.bodyHtml"
                >
                </p>

                <ul class="mt-4">
                  <li
                    v-for="(answer, answerIndex) in question.answers"
                    :key="answerIndex"
                    class="py-2 mt-2 border-b border-gray-300 last:border-b-0"
                  >
                    <label class="inline-flex items-center">
                      <input
                        v-model="choiceResponses[questionIndex]"
                        type="radio"
                        :class="['form-radio text-secondary h-6 w-6', {'cursor-not-allowed': showAnswers}]"
                        :name="index + 1"
                        :value="answer.uuid"
                        :disabled="showAnswers"
                        @change="handleAnswer(answer.uuid, question.uuid)"
                      />
                      <span
                        class="ml-2"
                        v-html="answer.bodyHtml"
                      ></span>
                    </label>
                  </li>
                </ul>
              </template>
              <!-- END COMPONENTIZE -->

              <!-- COMPONENTIZE -->
              <ul
                v-if="typeLikely"
                class="mt-4"
              >
                <li
                  v-for="(answer, answerIndex) in question.answers"
                  :key="answerIndex"
                  class="pb-4 mb-8 border-b border-gray-300"
                >
                  <p v-html="answer.bodyHtml"></p>

                  <label
                    v-if="likelyResponses[questionIndex]"
                    class="inline-flex items-center mt-4 mr-8"
                  >
                    <input
                      v-model="likelyResponses[questionIndex]['most_likely']"
                      type="radio"
                      :class="['form-radio text-secondary h-6 w-6', {'cursor-not-allowed': showAnswers}]"
                      :name="answerIndex + 1"
                      :value="answer.uuid"
                      :disabled="showAnswers"
                      @change="handleLikelyAnswer(questionIndex, question.uuid)"
                    />
                    <span class="ml-2">Most</span>
                  </label>

                  <label
                    v-if="likelyResponses[questionIndex]"
                    class="inline-flex items-center mt-4"
                  >
                    <input
                      v-model="likelyResponses[questionIndex]['least_likely']"
                      type="radio"
                      :class="['form-radio text-secondary h-6 w-6', {'cursor-not-allowed': showAnswers}]"
                      :name="answerIndex + 1"
                      :value="answer.uuid"
                      :disabled="showAnswers"
                      @change="handleLikelyAnswer(questionIndex, question.uuid)"
                    />
                    <span class="ml-2">Least</span>
                  </label>
                </li>
              </ul>
              <!-- END COMPONENTIZE -->

              <div class="inline-flex my-8">
                <BaseButton
                  class="mr-4"
                  :disabled="questionIndex < 1"
                  variant="inverse"
                  @click="prev"
                >
                  Back
                </BaseButton>
                <BaseButton
                  v-if="!isLastQuestion"
                  :disabled="!canPressNext"
                  :loading="submitInProgress"
                  @click="next()"
                >
                  Next
                </BaseButton>
                <AssessmentEndButton
                  v-if="isLastQuestion && !isExample"
                  :submit-error="submitError"
                  :submit-in-progress="submitInProgress"
                  button-type="button"
                  button-text="Finish"
                  @endTest="submitResponsesAndRedirect()"
                  @examCancelledAfterError="returnToDashboard"
                />

                <AssessmentExampleEndButton
                  v-if="isLastQuestion && isExample"
                  :modal-open="endModalOpen"
                  :is-showing-answers="showAnswers"
                  @open="endModalOpen = true"
                  @close="endModalOpen = false"
                  @showAnswers="setShowAnswers"
                >
                  <template v-slot="{ isShowingAnswers }">
                    <BaseButton
                      v-if="!isShowingAnswers"
                      @click="completedModalOpen = true"
                    >
                      Finish
                    </BaseButton>
                  </template>
                </AssessmentExampleEndButton>
              </div>

              <div
                v-if="isExample && showAnswers"
                class="mt-8 text-gray-600"
                v-html="question.explanation"
              >
              </div>
              <!-- END COMPONENTIZE -->
            </div>
          </section>
        </div>
      </div>
    </div>
    <div
      v-else
      class="my-12"
    >
      <Loader />
    </div>

    <AssessmentTimeUpModal
      v-if="!isExample"
      :modal-open="timeUpModalOpen"
      :assessments-dashboard-route="assessmentsDashboardRoute"
      @close="timeUpModalOpen = false"
    />

    <AssessmentFeedbackModal
      v-if="!isExample"
      :job-id="jobUuid"
      :modal-open="feedbackModalOpen"
      @close="closeFeedbackModal"
    />

    <AssessmentsCompleteModal
      v-if="!isExample"
      :modal-open="assessmentsCompleteModalOpen"
      @close="$router.push({ name: 'candidate-wizard-dashboard' })"
    />

    <AssessmentExampleCompleteModal
      v-if="isExample"
      :instruction-slug="exam.instruction_slug"
      :modal-open="completedModalOpen"
      @close="completedModalOpen = false"
      @showAnswers="setShowAnswers"
    />
  </div>
</template>

<script>
import AssessmentTimeUpModal from '@components/Assessments/AssessmentTimeUpModal'
import AssessmentEndButton from '@components/Assessments/AssessmentEndButton'
import AssessmentFeedbackModal from '@components/Assessments/AssessmentFeedbackModal'
import AssessmentsCompleteModal from '@components/Assessments/AssessmentsCompleteModal'

import AssessmentGraph from '@components/Assessments/AssessmentGraph'

import AssessmentExampleEndButton from '@components/Assessments/AssessmentExampleEndButton'
import AssessmentExampleCompleteModal from '@components/Assessments/AssessmentExampleCompleteModal'

import Timer from '@components/Timer'
import Loader from '@components/Loader'

import AssessmentDrives from '@components/Assessments/AssessmentDrives'

import AssessmentPersonality from '@components/Assessments/AssessmentPersonality'

import marked from 'marked'
import { mapGetters } from 'vuex'
import * as Sentry from '@sentry/browser'

export default {
  components: {
    AssessmentExampleEndButton,
    AssessmentExampleCompleteModal,
    AssessmentPersonality,
    AssessmentGraph,
    AssessmentDrives,
    AssessmentTimeUpModal,
    AssessmentEndButton,
    Timer,
    Loader,
    AssessmentFeedbackModal,
    AssessmentsCompleteModal
  },

  props: {
    exam: {
      type: Object,
      default: null
    },
    candidate: {
      type: Object,
      default: null
    },
    isExample: {
      type: Boolean,
      default: false
    },
    exampleQuestions: {
      type: Array,
      default: () => []
    },
    jobUuid: {
      type: String,
      default: null
    },
    jobWizardSlug: {
      type: String,
      default: null
    },
    jobExams: {
      type: Array,
      default: null
    },
    organisationId: {
      type: String,
      default: null
    },
    antiCheatData: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      marked,

      endModalOpen: false,
      timeUpModalOpen: false,
      completedModalOpen: false,
      feedbackModalOpen: false,
      assessmentsCompleteModalOpen: false,

      showAnswers: false,

      questionIndex: 0,
      choiceResponses: [],
      likelyResponses: [],

      submitInProgress: false,
      submitError: '',

      attemptExamSlugs: []
    }
  },

  computed: {
    ...mapGetters({
      haveQuestionsLoaded: 'exams/haveQuestionsLoaded',
      haveExamsLoaded: 'exams/haveExamsLoaded',
      storedQuestions: 'exams/questions',
      examBySlug: 'exams/examBySlug',

      candidateId: 'candidates/candidateId',
      token: 'candidates/token',
      candidateSittingToken: 'candidates/candidateSittingToken',
      jobId: 'candidates/jobId',
      extraTime: 'attempt/extraTime',

      personalityDataPages: 'personality/totalPages'
    }),

    /**
     * @return {Array}
     */
    questions() {
      return this.exampleQuestions.length ? this.exampleQuestions : this.storedQuestions(this.exam.slug)
    },

    /**
     * @return {String}
     */
    progress() {
      return (this.questionIndex / this.questionCount) * 100
    },

    /**
     * @return {Boolean}
     */
    typeLikely() {
      return this.exam.instruction_slug === 'likely'
    },

    /**
     * @return {Boolean}
     */
    typeChoice() {
      return this.exam.instruction_slug === 'choice'
    },

    /**
     * @return {Boolean}
     */
    typePersonality() {
      return this.exam.instruction_slug === 'personality'
    },

    /**
     * @return {Boolean}
     */
    typeDrives() {
      return this.exam.instruction_slug === 'drives'
    },

    /**
     * @return {Number}
     */
    questionCount() {
      if (this.typePersonality && this.exam.slug) {
        return this.personalityDataPages(this.exam.personalityTraits, this.exam.slug)
      }

      if (this.typeDrives) {
        return
      }

      if (!this.isExample && !this.haveQuestionsLoaded(this.exam.slug)) {
        return
      }

      return this.questions.length
    },

    /**
     * @return {Boolean}
     */
    isLastQuestion() {
      return !!(this.questionIndex === this.questionCount - 1)
    },

    /**
     * @return {String}
     */
    nextText() {
      return this.isLastQuestion ? 'Finish' : 'Next'
    },

    /**
     * Can the user press the next button?
     *
     * Mostly used for likely-type questions.
     *
     * @return {Boolean}
     */
    canPressNext() {
      if (!this.typeLikely) {
        return true
      }

      const question = this.likelyResponses[this.questionIndex]

      if (!question) {
        return false
      }

      if (question['least_likely'] === question['most_likely']) {
        return false
      }

      return question['least_likely'] && question['most_likely']
    },

    /**
     * For use with a nuxt-link to
     *
     * @return {Object}
     */
    assessmentsDashboardRoute() {
      if (this.$usesFeature('classic-jobs')) {
        return {
          name: 'assessments',
          query: {
            candidate: this.candidateId,
            job: this.jobId,
            token: this.token,
            sitting: this.candidateSittingToken
          }
        }
      }

      if (this.$usesFeature('candidate-wizard')) {
        return {
          name: 'candidate-wizard-job', params: { job: this.jobWizardSlug }
        }
      }
    },

    /**
     * @return {Boolean}
     */
    showEndTestButton() {
      return !this.isExample && !this.typePersonality
    },

    /**
     * @return {Boolean}
     */
    candidateWizardJobId() {
      if (!this.$usesFeature('candidate-wizard')) {
        return
      }
      return this.jobUuid
    },

    /**
     * @return {Boolean}
     */
    isLastCoreExam() {
      return this.jobExams.length === this.attemptExamSlugs.length
    }
  },

  created() {
    if (this.organisationId) {
      this.getCustomExamQuestions()
    } else {
      this.getQuestions()
    }

    this.getAttemptExamSlugs()
  },

  methods: {
    getQuestions() {
      if (this.isExample) {
        // Questions are all in local JSON
        this.restart()
        return
      }

      const questionsRequest = this.typePersonality ? this.$store.dispatch('personality/getStatements', this.$route.params.assessment) : this.$store.dispatch('exams/getQuestions', this.$route.params.assessment)

      questionsRequest.then(() => {
        this.restart()
      })
        .catch(() => {
          throw new Error('Cannot find exam')
        })
    },

    getCustomExamQuestions() {
      this.$store.dispatch('exams/getCustomExamQuestions', {
        examSlug: this.$route.params.assessment,
        examUuid: this.exam.uuid
      })
        .then(() => {
          this.restart()
        })
        .catch(() => {
          throw new Error('Cannot find custom exam')
        })
    },

    restart() {
      this.questionIndex = 0

      // Creates response arrays ready to be filled by the user
      this.choiceResponses = Array(this.questionCount).fill(null)
      this.likelyResponses = Array.from({ length: this.questionCount }, () => ({ most_likely: null, least_likely: null }))

      // Clears existing answers
      this.$store.commit('attempt/clearAnswers')
    },

    handleNavigation(index) {
      this.questionIndex = index - 1
    },

    filteredQuestions(examSlug) {
      if (examSlug === 'drives') {
        return this.drives
      } else {
        return this.questions
      }
    },

    /**
     * Take the user to the next question, or submit response if last
     */
    next() {
      if (this.submitInProgress) {
        return
      }

      if (this.questionIndex + 1 < this.questionCount) {
        return this.questionIndex++
      }
    },

    prev() {
      if (this.questionCount > 0) {
        return this.questionIndex--
      }
    },

    questionNumberStyles(index) {
      return [
        { 'inline-blo k border-b-2 border-secondary pb-1 font-semibold leading-none text-gray-800': this.questionIndex === index - 1 },
        { 'text-secondary': this.hastheQuestionBeenAnswered(index) }
      ]
    },

    hastheQuestionBeenAnswered(index) {
      // Has the choice question been answered?
      if (this.typeChoice) {
        return this.choiceResponses[index - 1] !== null
      }

      // Has the likely question been answered?
      if (this.typeLikely) {
        if (!this.likelyResponses[index - 1]) {
          return false
        }
        return Object.values(this.likelyResponses[index - 1])
          .every(response => {
            return response !== null
          })
      }

      // // Has the personality question been answered?
      // if (this.typePersonality) {
      //   return true
      // }
    },

    setShowAnswers() {
      this.endModalOpen = false
      this.completedModalOpen = false
      this.showAnswers = true
      this.questionIndex = 0

      // Set correct Choice answers for Example questions
      if (this.typeChoice) {
        const correctAnswers = this.questions.map(question => {
          return question.answers.find(answer => answer.correct)
        })
        this.choiceResponses = correctAnswers.map(correctAnswer => correctAnswer.uuid)
      }

      // Set correct Likely answers for Example questions
      if (this.typeLikely) {
        const mostLikely = this.questions.map(question => {
          return question.answers.find(answer => answer.mostLikely)
        })
        const leastLikely = this.questions.map(question => {
          return question.answers.find(answer => answer.leastLikely)
        })

        this.likelyResponses = mostLikely.map((likely, i) => {
          return (
            {
              most_likely: likely.uuid,
              least_likely: leastLikely[i].uuid
            }
          )
        })
      }
    },

    /**
     * @param {string} answerUuid
     * @param {string} questionUuid
     */
    handleAnswer(answerUuid, questionUuid) {
      this.$store.dispatch('attempt/handleAnswer', {
        answer: { uuid: answerUuid }, questionUuid
      })
    },

    handleLikelyAnswer(questionIndex, questionUuid) {
      this.$set(this.likelyResponses, this.likelyResponses)
      this.$store.dispatch('attempt/handleAnswer', {
        answer: {
          likely: {
            most: this.likelyResponses[questionIndex]['most_likely'],
            least: this.likelyResponses[questionIndex]['least_likely']
          }
        },
        questionUuid
      })
    },

    timeIsUp() {
      Promise.resolve(this.submitResponses())
        .then(() => {
          this.timeUpModalOpen = true
        })
    },

    /**
     * @return {Promise}
     */
    submitResponses() {
      if (this.isExample) {
        return
      }

      this.submitInProgress = true

      const attemptData = {
        exam: this.exam,
        jobUuid: this.candidateWizardJobId,
        antiCheatData: this.antiCheatData,
        usedExtraTime: this.extraTime
      }

      if (this.typeDrives) {
        return this.$store.dispatch('drives/endDrivesExam', attemptData)
      }

      if (this.typePersonality) {
        return this.$store.dispatch('personality/endPersonalityExam', attemptData)
      } else {
        return this.$store.dispatch('attempt/endExam', attemptData)
          .catch(error => {
            if (error.response.status !== 409) {
              throw error
            }
            Sentry.setUser({ candidateId: this.candidateId })
            this.submitError = 'Sorry, this test has already been taken'
            this.submitInProgress = false
            console.error('Attempt for this exam has already been made')
            throw error
          })
      }
    },

    /**
     * Submit the responses, the redirect if no error
     *
     * @return void
     */
    submitResponsesAndRedirect() {
      if (this.submitInProgress) {
        console.error('Submmission already in progress')
        return
      }

      this.submitInProgress = true
      this.timeIsUp = true

      this.submitResponses()
        .then(() => {
          this.submitInProgress = false

          // Handle Feedback and submit to employer
          if (this.$usesFeature('candidate-wizard')) {
            this.attemptExamSlugs.push(this.exam.slug)

            if (this.isLastCoreExam) {
              this.feedbackModalOpen = true
              return
            }
          }

          this.returnToDashboard()
        })
        .catch(error => {
          this.submitInProgress = false

          console.error(error)
          if (!this.submitError) {
            this.submitError = 'Sorry, an error occured'
          }
          this.completedModalOpen = true
        })
    },

    closeFeedbackModal() {
      this.feedbackModalOpen = false
      this.assessmentsCompleteModalOpen = true
    },

    returnToDashboard() {
      this.$router.push(this.assessmentsDashboardRoute)
    },

    getAttemptExamSlugs() {
      // Used to work out the last exam for Candidate Wizards
      if (this.isExample || this.$usesFeature('classic-jobs')) {
        return []
      }

      if (!this.candidate.attempts.length) {
        return []
      }
      this.attemptExamSlugs = this.candidate.attempts.map(attempt => attempt.examSlug)
    },

    markdownTableRowStyling(table) {
      // Bold last table row if a "total"
      if (table.includes('Total')) {
        return 'markdown-table'
      }
    },

    /**
     * Decides the CSS classes for an image
     *
     * @param {Object} question
     * @param {Object} exam
     * @return {string}
     */
    questionImageClasses(question, exam) {
      // All images should have auto margins
      const classes = ['mx-auto']

      // If an exam has an organisationId, it’s a CustomExam. Images from
      // CustomExams are allowed to be full-sized as they are managed by
      // Cloudinary
      if (!exam.organisationId && !exam.examSuiteSlug) {
        // Legacy option for older tests sent directly
        classes.push('md:max-w-lg xl:max-w-xl')
      }

      let sizeClass = 'w-full'
      if (question.imageData) {
        // we have imageData, which will tell us the height and width if possible
        const imageData = question.imageData
        if (imageData.height && imageData.width) {
          if (imageData.height > imageData.width) {
            // Image is portrait, so make it half height and hope for the best
            sizeClass = 'h-1/2'
          }
        }
      }
      classes.push(sizeClass)

      return classes
    },

    /**
     * @return {Boolean}
     */
    questionHasAsset(question) {
      if (!question) {
        return false
      }
      return !!question.image_url || !!question.articleHtml || (question.graphs && !!question.graphs.length) || !!question.table
    }
  }
}
</script>

<style>
.article p, .article ul {
  margin-bottom: 1em;
}
</style>
